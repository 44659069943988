import * as React from "react"
import { graphql } from "gatsby"
import { Container, FlexList, Box, Heading } from "../components/ui"
import { BlogPost } from "../templates/blog-post"
import { theme } from "../theme.css"
import Head from "../components/head"
import { PostCard } from "../basic/PostCard"
import { TabContainer } from "../basic/TabContainer"

export interface BlogIndexProps {
  posts: BlogPost[]
}

export default function news({ data }) {
  const posts = data.allWpPost.nodes

  const [selectedTabIds, setSelectedTabIds] = React.useState<
    Record<number, boolean>
  >({
    0: true,
  })

  const shownCategories = {}
  if (selectedTabIds[0]) {
    shownCategories["Allgemein"] = true
    shownCategories["Spielbericht"] = true
    shownCategories["Vorbericht"] = true
  } else if (selectedTabIds[1]) {
    shownCategories["Allgemein"] = true
  } else if (selectedTabIds[2]) {
    shownCategories["Spielbericht"] = true
  } else if (selectedTabIds[3]) {
    shownCategories["Vorbericht"] = true
  }

  const filteredPosts = posts.filter(
    (post) => shownCategories[post.categories.nodes[0].name]
  )

  return (
    <>
      <Head title="Neuigkeiten" />
      <Container>
        <Box paddingY={3}>
          <Heading as="h1">Neuigkeiten</Heading>
          <hr style={{ border: `1px solid ${theme.colors.text}` }} />
          <Box width="full" paddingY={1}>
            <TabContainer
              initialSelectedTabs={selectedTabIds}
              onChange={(newTabs) => {
                setSelectedTabIds(newTabs)
              }}
              tabs={[
                { text: "Alle" },
                { text: "Allgemein" },
                { text: "Spielberichte" },
                { text: "Vorberichte" },
              ]}
            />
          </Box>
          <FlexList variant="start" gap={0} gutter={3} responsive wrap>
            {filteredPosts.map((post) => (
              <Box as="li" key={post.id} padding={3} width="half">
                <Box padding={2} style={{ boxShadow: theme.shadows.small }}>
                  <PostCard {...post} />
                </Box>
              </Box>
            ))}
          </FlexList>
        </Box>
      </Container>
    </>
  )
}

export const query = graphql`
  query {
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        categories {
          nodes {
            name
          }
        }
        isSticky
        excerpt
        slug
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
