import React from "react"
import {
  BlockLink,
  HomepageImage,
  Kicker,
  Subhead,
  Text,
} from "../components/ui"
import { BlogAuthor } from "../templates/blog-post"
import { theme } from "../theme.css"
interface PostCardSmallProps {
  slug: string
  image?: HomepageImage
  title?: string
  categories?: { nodes: { name: string }[] }
  isSticky: boolean
}

interface PostCardProps extends PostCardSmallProps {
  excerpt?: string
  author?: BlogAuthor
  date: string
}

const formatDate = (date) => {
  return new Date(date).toLocaleString("de", {
    day: "numeric",
    month: "long",
    year: "numeric",
  })
}

export function PostCard({
  slug,
  image,
  title,
  excerpt,
  author,
  categories,
  date,
  ...props
}: PostCardProps) {
  return (
    <BlockLink {...props} to={`/news/${slug}`}>
      <Subhead style={{ marginBottom: 0 }}>
        {categories && <Kicker>{categories.nodes[0].name}</Kicker>}
        {title}
      </Subhead>
      {excerpt && (
        <div
          style={{ color: theme.colors.long_text }}
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />
      )}
      <Text style={{ marginBottom: 0 }}>{`${formatDate(date)} ${
        author ? author.name : ""
      }`}</Text>
    </BlockLink>
  )
}

export function PostCardSmall({
  slug,
  image,
  title,
  author,
  categories,
  date,
  isSticky,
  ...props
}: PostCardProps) {
  const categoryNames = categories.nodes?.map((cat) => cat.name)

  return (
    <BlockLink
      {...props}
      style={{
        color: "white",
        border: isSticky ? "white 2px solid" : "",
        borderRadius: "4px",
        padding: "8px",
      }}
      to={`/news/${slug}`}
    >
      <Subhead style={{ marginBottom: 0, fontSize: "28px" }}>
        {categories && <Kicker>{categoryNames.join(" - ")}</Kicker>}
        {title}
      </Subhead>

      {!isSticky && (
        <Text style={{ marginBottom: 0 }}>{`${formatDate(date)} ${
          author ? author.name : ""
        }`}</Text>
      )}
    </BlockLink>
  )
}
