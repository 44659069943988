import React, { useEffect, useState } from "react"

import { Flex, FlexList, Heading, Kicker, Box, Link } from "../components/ui"
import { media, navlink } from "../components/ui.css"
import { theme } from "../theme.css"
import { boxShadow } from "./box-shadow.css"

interface ITabContainer {
  tabs: { text: string; content?: JSX.Element }[]
  headingText?: string
  multipleItemsSelectable?: boolean
  onChange?: (selectedTabs: Record<number, boolean>) => void
  initialSelectedTabs?: Record<number, boolean>
}

export const TabContainer = (props: ITabContainer) => {
  const {
    tabs,
    headingText,
    onChange = () => undefined,
    multipleItemsSelectable = false,
    initialSelectedTabs = { 0: true },
  } = props

  const [selectedTabIds, setSelectedTabIds] =
    useState<Record<number, boolean>>(initialSelectedTabs)

  const changeSelected = (e) => {
    e.preventDefault()
    const { tabid } = e.target.dataset
    const parsedTabId = parseInt(tabid)
    let newTabIds
    if (multipleItemsSelectable) {
      newTabIds = {
        ...selectedTabIds,
        [parsedTabId]: !selectedTabIds[parsedTabId],
      }
    } else {
      newTabIds = { [parsedTabId]: true }
    }
    setSelectedTabIds(newTabIds)
    onChange(newTabIds)
  }

  const [isMobileSize, setIsMobileSize] = useState(false)

  React.useEffect(() => {
    setIsMobileSize(!window.matchMedia("(min-width:40em)")?.matches)
  }, [])

  return (
    <>
      {headingText && (
        <Heading center>
          <Kicker>{headingText}</Kicker>
        </Heading>
      )}
      <FlexList
        gap={isMobileSize ? 1 : 4}
        style={{ justifyContent: "center", marginBottom: "16px" }}
        responsive
      >
        {tabs.map((navItem, index) => {
          const isSelectedTab = selectedTabIds[index]
          const listStyles = isSelectedTab
            ? {
                borderBottom: `2px solid ${theme.colors.primary}`,
                borderRadius: "2px",
              }
            : {}

          return (
            <li
              key={index}
              style={{
                height: "30px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                ...listStyles,
              }}
            >
              <Link
                data-tabid={index}
                onClick={changeSelected}
                className={navlink}
                style={
                  isSelectedTab
                    ? { color: theme.colors.primary, fontWeight: "bold" }
                    : {}
                }
              >
                {navItem.text}
              </Link>
            </li>
          )
        })}
      </FlexList>
      {multipleItemsSelectable
        ? null
        : tabs[
            Object.keys(selectedTabIds).find((tabId) => selectedTabIds[tabId])
          ].content ?? null}
    </>
  )
}
